// extracted by mini-css-extract-plugin
export var authorImg = "cyber-insurer-module--authorImg--c7677";
export var authorJob = "cyber-insurer-module--authorJob--00619";
export var authorName = "cyber-insurer-module--authorName--9f14b";
export var billetShadow = "cyber-insurer-module--billet-shadow--6a5cc";
export var center = "cyber-insurer-module--center--81050";
export var challengesList = "cyber-insurer-module--challengesList--3830e";
export var colorScheme__background__grayPale = "cyber-insurer-module--colorScheme__background__gray-pale--3c812";
export var cyberInsuranceBlock = "cyber-insurer-module--cyberInsuranceBlock--1d72f";
export var deliverablesList = "cyber-insurer-module--deliverablesList--1ccef";
export var details = "cyber-insurer-module--details--04bb0";
export var doubleImages = "cyber-insurer-module--doubleImages--d2eb8";
export var featuresList = "cyber-insurer-module--featuresList--40b9e";
export var heading = "cyber-insurer-module--heading--8c8c2";
export var headingText = "cyber-insurer-module--headingText--1eb15";
export var icomoon = "cyber-insurer-module--icomoon--3f7ea";
export var iconWrapper = "cyber-insurer-module--iconWrapper--32c37";
export var imageBox = "cyber-insurer-module--imageBox--8f5e5";
export var imageFirstBlock = "cyber-insurer-module--imageFirstBlock--182f3";
export var lastTextBlock = "cyber-insurer-module--lastTextBlock--b1416";
export var listBlock = "cyber-insurer-module--listBlock--5da9b";
export var lowercase = "cyber-insurer-module--lowercase--8240c";
export var mainImage = "cyber-insurer-module--mainImage--d6f43";
export var mainList = "cyber-insurer-module--mainList--0897c";
export var quoteBlock = "cyber-insurer-module--quoteBlock--b536d";
export var scopeWrapper = "cyber-insurer-module--scopeWrapper--3d08f";
export var shine = "cyber-insurer-module--shine--943de";
export var teamImg = "cyber-insurer-module--teamImg--20ae9";
export var teamItem = "cyber-insurer-module--teamItem--1e790";
export var teamListName = "cyber-insurer-module--teamListName--e78fc";
export var teamMobile = "cyber-insurer-module--teamMobile--8601a";
export var teamWrapper = "cyber-insurer-module--teamWrapper--17dec";
export var technologyWrapper = "cyber-insurer-module--technologyWrapper--fcbff";
export var textBlock = "cyber-insurer-module--textBlock--5ccf9";
export var textBox = "cyber-insurer-module--textBox--7ba39";
export var textCenter = "cyber-insurer-module--textCenter--37c0d";
export var textFirstBlock = "cyber-insurer-module--textFirstBlock--2dd5e";
export var thirdBlockText = "cyber-insurer-module--thirdBlockText--3059a";
export var transition = "cyber-insurer-module--transition--c29d2";
export var transitionBackground = "cyber-insurer-module--transition-background--c6662";
export var transitionReverse = "cyber-insurer-module--transition-reverse--e7a3a";
export var twoColumns = "cyber-insurer-module--twoColumns--e40cb";
export var uppercase = "cyber-insurer-module--uppercase--b4983";